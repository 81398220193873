import { useContext, useState, useEffect } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

import AuthContext from '../../contexts/auth-context';
import StadiumSelect from './StadiumSelect/StadiumSelect';
import Button from '../UI/Input/Button/Button';
import StadiumSearchPopUp from '../PopUps/StadiumSearchPopUp/StadiumSearchPopUp';
import Search from '../UI/Icons/Search/Search';

import classes from './HeaderTitle.module.css';

const HeaderTitle = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const params = useParams();
  const routeMatch = useRouteMatch();

  const [selectedStadiumId, setSelectedStadiumId] = useState(+params.stadiumId);
  const [isStadiumSearchPopUpOpen, setIsStadiumSearchPopUpOpen] =
    useState(false);

  const changeSelectedStadiumIdHandler = (stadiumId) => {
    setSelectedStadiumId(+stadiumId);
    const stadium = authCtx.stadiumsList.find((stadium) => {
      return stadium.id === +stadiumId;
    });
    const firstUrlPath = routeMatch.url.split('/')[1];

    history.push(
      `/${firstUrlPath}/stadium/${stadium.id}/zone/${stadium.zones[0].id}`
    );
  };

  const changeStadiumSearchPopUpOpenHandler = () => {
    setIsStadiumSearchPopUpOpen((prev) => !prev);
  };

  useEffect(() => {
    setSelectedStadiumId(+params.stadiumId);
  }, [params.stadiumId]);

  return (
    <div className={classes.HeaderTitle}>
      {authCtx.userRole === '마스터' && (
        <Button
          attribute={{ type: 'button' }}
          classList={['Button-Search', 'HeaderTitle__Button-Search']}
          onClick={() => setIsStadiumSearchPopUpOpen(true)}
        >
          <Search />
        </Button>
      )}
      <h2 className={classes['HeaderTitle__Text']}>
        <StadiumSelect
          selectedStadiumId={selectedStadiumId}
          stadiums={authCtx.stadiumsList}
          onChangeSelectedStadium={changeSelectedStadiumIdHandler}
        />
      </h2>
      {authCtx.userRole === '마스터' && isStadiumSearchPopUpOpen && (
        <StadiumSearchPopUp
          stadiums={authCtx.stadiumsList}
          onChangeSelectedStadium={changeSelectedStadiumIdHandler}
          onClose={changeStadiumSearchPopUpOpenHandler}
          isOpen={isStadiumSearchPopUpOpen}
        />
      )}
    </div>
  );
};

export default HeaderTitle;
